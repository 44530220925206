import React from "react"
import { Link } from "gatsby"

// import { rhythm, scale } from "../utils/typography"
import "./layout.css"

const Layout = ({ location, title, children }) => {
  let header = (
    <nav
      className="navbar navbar-expand-lg navbar-dark"
      style={{
        borderRadius: "0 0 4px 4px",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 15,
        width: "100vw",
        backgroundColor: "#151D32",
      }}
    >
      <div className="container">
        <a className="navbar-brand" href="https://campus24.in">
          <img
            className="img-header"
            style={{margin:0}}
            src="/static/images/Logo_header.svg"
            alt="Campus 24"
          />
        </a>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav">            
            <a
              className="nav-item nav-link"
              href="https://campus24.in/why-campus24/"
              style={{
                color: "rgb(131, 204, 192)",
                maxWidth: "120px",
                fontFamily: '"lato"',
                fontSize: "17.5px",
                fontWeight: 400,
              }}
            >
              Why C24
            </a>
            <Link 
                className="nav-item nav-link"
                  to = "/"
                  style={{
                    color: "rgb(131, 204, 192)",
                    maxWidth: "120px",
                    fontFamily: '"lato"',
                    fontSize: "17.5px",
                    fontWeight: 400,
                  }}
            >
            Blogs
            </Link>
            <a
              className="nav-item nav-link"
              href="https://campus24.in/about-us"
              style={{
                color: "rgb(131, 204, 192)",
                maxWidth: "120px",
                fontFamily: '"lato"',
                fontSize: "17.5px",
                fontWeight: 400,
              }}
            >
              About
            </a>
            <a
              className="nav-item nav-link"
              href="https://campus24.in/contact-us"
              style={{
                color: "rgb(131, 204, 192)",
                maxWidth: "120px",
                fontFamily: '"lato"',
                fontSize: "17.5px",
                fontWeight: 400,
              }}
            >
              Contact
            </a>
          </div>
        </div>
        <a href="https://play.google.com/store/apps/details?id=com.bytera.campus24" className="nav-app" style={{cursor:'pointer'}}>Get the app</a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
          style={{ outline: "none" }}
        >
          <span className="navbar-toggler-icon" />
        </button>
      </div>
    </nav>
  )
  let blogFooter = (
    <footer className="pb-4 pt-2" style={{minHeight: '19rem', maxWidth: '100vw', overflow: 'hidden'}}>
  <div className="container">
    <div className="row footer-2-part-flex">
      <div className="row col-4 footer-2-part mt-4">
        <div className="col-6 footer-list-container">
          <div className="container">
            <a href="https://campus24.in">
              <div>
                <img src="/static/images/footerpic.png" className="footer-campus-logo" alt="Campus 24" />
              </div>
            </a>
          </div>
        </div>
        <div className="col-6 footer-list-container">
          <h3 style={{color: '#0CFFA5', fontFamily: '"lato"', fontSize: '20px', fontWeight: 900}}>PRODUCTS</h3>
          <ul style={{listStyle: 'none', padding: 0}}>
            <li style={{display: 'block'}}><a href="https://campus24.in/why-campus24/">Why Campus24?</a></li>
            <li style={{display: 'block'}}><a href="https://www.youtube.com/channel/UCfQwRcV5TLh-ol546kQcdJw">Demo</a></li>
            <li style={{display: 'block'}}><a href="https://www.youtube.com/channel/UCfQwRcV5TLh-ol546kQcdJw">YouTube</a></li>
          </ul>
        </div>
      </div>
      <div className="row col-4 footer-2-part mt-4">
        <div className="col-6 footer-list-container" style={{paddingLeft:'40px'}}>
          <h3 style={{color: '#0CFFA5', fontFamily: '"lato"', fontSize: '20px', fontWeight: 900}}>RESOURCES</h3>
          <ul style={{listStyle: 'none', padding: 0}}>
            <li style={{display: 'block'}}><a href="https://campus24.in/faq">FAQs</a></li>
            <li style={{display: 'block'}}><Link to = "/">Blogs</Link></li>
            <li style={{display: 'block'}}><a href="https://www.youtube.com/channel/UCfQwRcV5TLh-ol546kQcdJw">Podcast</a></li>
          </ul>
        </div>
        <div className="col-6 footer-list-container">
          <h3 style={{color: '#0CFFA5', fontFamily: '"lato"', fontSize: '20px', fontWeight: 900}}>COMPANY</h3>
          <ul style={{listStyle: 'none', padding: 0}}>
            <li style={{display: 'block'}}><a href="https://campus24.in/about-us">About</a></li>
            <li style={{display: 'block'}}><a href="https://campus24.in/careers">Careers</a></li>
            <li style={{display: 'block'}}><a href="https://campus24.in/contact-us">Contact</a></li>
          </ul>
        </div>
      </div>
      <div className="row col-4 footer-2-part mt-4">
        <div className="col-6 footer-list-container">
          <h3 style={{color: '#0CFFA5', fontFamily: '"lato"', fontSize: '20px', fontWeight: 900}}>LEGAL</h3>
          <ul style={{listStyle: 'none', padding: 0}}>
            <li style={{display: 'block'}}><a href="https://campus24.in/terms/">Terms &amp; Conditions</a></li>
            <li style={{display: 'block'}}><a href="https://campus24.in/privacy/">Privacy</a></li>
            <li style={{display: 'block'}}><a href="https://campus24.in/cookies/">Cookie</a></li>
          </ul>
        </div>
        <div className="col-6 footer-list-container">
          <h3 style={{color: '#0CFFA5', fontFamily: '"lato"', fontSize: '20px', fontWeight: 900}}>SOCIAL</h3>
          <div className="row" style={{position: 'relative', left: '12px'}}>
            <div style={{marginLeft: '4px'}}>
              <ul style={{listStyle: 'none', padding: 0}}>
                <li style={{display: 'block'}}><a href="https://www.facebook.com/campus24app">Facebook</a></li>
                <li style={{display: 'block'}}><a href="https://www.instagram.com/campus24app/">Instagram</a></li>
                <li style={{display: 'block'}}><a href="https://twitter.com/C24_app">Twitter</a></li>
              </ul>
            </div>
            <div className="ml-2">
              <ul style={{listStyle: 'none', padding: 0}}>
                <li style={{display: 'block'}}><a href="https://www.linkedin.com/company/campus24">LinkedIn</a></li>
                <li style={{display: 'block'}}><a href="https://www.quora.com/profile/Campus24-1">Quora</a></li>
                <li style={{display: 'block'}}><a href="mailto:contact@campus24.in">Email</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="container" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap'}}>
        <p className="links">
          <a href="https://play.google.com/store/apps/details?id=com.bytera.campus24" className="footer-app">Get the app</a>
          <a href="https://play.google.com/store/apps/details?id=com.bytera.campus24" style={{maxWidth: 'max-content', width: 'max-content'}} align="right"><img src="/static/images/play.png" className="play play-2" /></a>
        </p>
        <p className="row" align="center">
          <form className="form-email-2" method="POST" action="https://us-central1-campus24-e0a13.cloudfunctions.net/SendAppLink">
            <div className="col-6">
              <input type="email" name="email" placeholder="EMAIL-ID" required />
            </div>
            <div className="col-6" style={{position: 'relative', top: '15px'}}>
              <input type="submit" value="GET DOWNLOAD LINK" data-target="#EmailSentModalCenter" />
              <p style={{fontFamily: '"lato"', fontSize: '15px', fontWeight: 400, paddingTop: '8px', paddingLeft: 0, color: '#0CFFA5'}} align="center">*Available only on Android</p>
            </div>
          </form>
        </p>
      </div>
    </div>
  </div>
</footer>
  )
  return (
    <div
      style={{
        maxWidth: "100vw",
        overflowX: "hidden",
      }}
    >
      <header>{header}</header>
      <main className="pt-5">{children}</main>
      {blogFooter}
      <div
        className="row"
        style={{
          maxWidth: "100vw",
          backgroundColor: "rgb(12, 17, 29)",
          margin: 0,
        }}
      >
        <div
          className="container"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <div className="links" style={{ height: "32px", paddingTop: "4px" }}>
            <p
              href="#"
              className="footer-app"
              style={{
                color: "rgb(58, 83, 143)",
                transform: "scale(1)",
                fontSize: "smaller",
                marginBottom: "4px",
              }}
            >
              Copyright © {new Date().getFullYear()}, Bytera Ltd. | All Rights Reserved
            </p>
          </div>
        </div>
      </div>      
      <div
        className="modal fade"
        id="EmailSentModalCenter"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Mail Sent
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body" align="center">
              <img
                src="/static/images/Email-Sent.png"
                style={{ width: "150px" }}
              />
              <br />
              <br />
              <h4>Check Your Email</h4>
              <br />
              <p>We have sent the link to you. Get the app now.</p>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Layout
